.u-ml {
  margin-left: 2rem;
  @media screen and (min-width: 768px) {
    margin-left: 15rem;
  }
}
.u-mr {
  margin-right: 2rem;
  @media screen and (min-width: 768px) {
    margin-left: 15rem;
  }
}

.u-links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.u-text-decoration-none {
  text-decoration: none;
}

#u-dn {
  display: none;
}
.u-dn {
  display: none;
}
.u-tdn {
  text-decoration: none;
}

.u-btn {
  transition: all 0.2s;
  &:hover {
    transform: scale(1.012);
  }
  &:active,
  &:target {
    transform: scale(1-0.015);
  }
}

#u-bg-img {
  background-image: url(../assets/mobile/projects.png);
}
#u-bg-black {
  background-color: black;
}
