@import url(../components/hero/_hero.scss);
@import url(../components/about/_about.scss);
@import url(../components/contact/_contact.scss);
@import url(../components/projects/_projects.scss);
@import url(../components/skills/_skills.scss);
@import url(./_utilities.scss);

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

#dn {
  visibility: hidden;
  opacity: 0;
  transition: all 2s;
}

img {
  max-width: 100%;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  background-color: black;
}

.App {
  width: 100vw;
  height: 100vh;

  &__container {
    width: 100%;
    height: 100%;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;

    .container {
      scroll-snap-align: start;
      height: 100vh;

      background-repeat: no-repeat;
      background-size: cover;
      // background-attachment: fixed;
      @media screen and (min-width: 768px) {
        // margin-left: 10rem;
      }

      color: white;

      &:nth-last-of-type(2) {
        min-height: 100vh;
        overflow-y: scroll;
      }
      &:nth-child(1) {
      }
      &:nth-child(3) {
        // background-attachment: fixed;
        background-image: url(../assets/mobile/blur.png);
        background-repeat: no-repeat;
        background-size: cover;
      }
      &:nth-child(4) {
      }
      &:nth-child(5) {
        // background-attachment: fixed;
        background-image: url(../assets/mobile/skills.png);
        min-height: 100vh;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  html {
    font-size: 80%;
  }
}
@media screen and (max-height: 750px) {
  html {
    font-size: 80%;
  }
}
@media screen and (max-width: 1000px) and (min-width: 768px) {
  html {
    font-size: 75%;
  }
}
