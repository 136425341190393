@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-size;
    height: $item-size;
    margin: -($item-size / 2);
    $theme-colors: (
      "primary": #00695f,
      "info": #00a59b,
    );

    $angle: (360 / $item-count);

    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg)
          translate($circle-size / 2+3)
          rotate($rot * -1deg)
          translateX(0rem)
          translateY(3.5rem);

        @media screen and (min-width: 768px) {
          transform: rotate($rot * 1deg) translate($circle-size / 2+1)
            rotate($rot * -1deg) translateX(0rem) translateY(3.5rem);
        }
        @media screen and (min-width: 875px) {
          transform: rotate($rot * 1deg) translate($circle-size / 2+5)
            rotate($rot * -1deg) translateX(0rem) translateY(3.5rem);
        }
      }

      $rot: $rot + $angle;
    }
  }
}

.skills__container {
  overflow-x: hidden;
  overflow-y: hidden;
  &__content {
    height: 100%;
    position: relative;
    &__heading {
      position: absolute;
      left: 50%;
      top: 45%;
      transform: translate(-50%, -50%);
      color: transparent;
      -webkit-text-stroke: 1px white;
      animation: on-off 5s infinite;
      font-size: 2rem;
      @media screen and (max-width: 380px) {
        top: 49%;
      }
      @media screen and (max-height: 675px) {
        top: 48%;
      }
      @media screen and (max-height: 628px) {
        top: 48%;
      }
      @media screen and (max-height: 600px) {
        top: 50%;
      }
    }

    ul {
      height: 100%;
      display: grid;
      grid-template-columns: 5rem 5rem 5rem 5rem;
      grid-template-rows: 8rem 8rem 8rem;
      grid-column-gap: 1rem;
      position: absolute;
      left: 50%;
      top: 75%;
      transform: translate(-50%, -50%) rotate(0deg);

      @media screen and (max-width: 380px) {
        top: 80%;
      }

      li {
        display: flex;
        flex-direction: column;
        text-align: center;
        animation: on-off 2s infinite;

        &:nth-child(1) {
          span {
            margin-bottom: 1rem;
          }
        }
        &:nth-child(2) {
          grid-area: 3/2/3/4;
          span {
            margin-bottom: 1.5rem;
          }
        }
        &:nth-child(3) {
          grid-area: 2/1/2/2;
        }
        &:nth-child(4) {
          grid-area: 1/2/1/4;
          span {
            margin-bottom: 0.5rem;
          }
        }
        &:nth-child(5) {
          span {
            margin-bottom: 1rem;
          }
        }
        &:nth-child(6) {
          grid-area: 3;
        }
        &:nth-child(7) {
          grid-area: 3/4/3/4;
        }
        &:nth-child(8) {
          grid-area: 2/4/2/4;
          span {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .skills__container {
    &__content {
      width: 100%;
      margin-inline: auto;
      display: flex;
      justify-content: center;

      align-items: center;

      height: 100%;
      position: relative;

      &__heading {
        animation: rotate2 10s linear infinite;
        left: 50%;
        top: 50%;
      }

      &__content {
        transform: rotate(0deg);
        animation: rotate 10s linear infinite;
        height: 50%;
        width: 50%;
        position: relative;
      }

      ul {
        @include on-circle(
          $item-count: 8,
          $circle-size: 18rem,
          $item-size: 6rem
        );
        height: 100%;
        display: block;

        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%) rotate(0deg);

        @media screen and (max-width: 380px) {
          top: 80%;
        }
        li {
          span {
            &:nth-last-of-type(2) {
              // animation: rotate2 3s linear infinite;
              // margin-bottom: -2rem;
            }
          }
        }
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
    color: transparent;
    -webkit-text-stroke: 3px white;
  }
  50% {
    color: white;
    -webkit-text-stroke: 1px transparent;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
    color: transparent;
    -webkit-text-stroke: 1px white;
  }
}

@keyframes on-off {
  0% {
    color: transparent;
    -webkit-text-stroke: 3px white;
  }
  50% {
    color: white;
    -webkit-text-stroke: 1px transparent;
  }
  100% {
    color: transparent;
    -webkit-text-stroke: 1px white;
  }
}
