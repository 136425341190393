.projects__container {
  &__content {
    // background-color: black;
    width: 100%;
    height: 100%;
    // background-attachment: fixed;
    background-image: url(../../assets/mobile/projects.png);
    background-size: cover;
    background-repeat: no-repeat;
    overflow-y: scroll;

    @media screen and (min-width: 1050px) {
      background-image: url(../../assets/pc/projects.png);
    }
    &__heading {
      padding-top: 13rem;
      font-size: 5rem;
      text-align: center;
      font-weight: 600;
      -webkit-text-stroke: 1px white;
      animation: on-off 5s infinite;
      color: transparent;
    }

    &__card__container {
      &__card {
        width: 17.5rem;
        margin-inline: auto;
        margin-bottom: 8rem;
        border: 1px solid white;
        border-top: none;
        text-align: center;
        position: relative;

        &:nth-of-type(1) {
          margin-top: 5rem;
        }
        &:nth-last-of-type(1) {
          margin-bottom: 15rem;
        }

        &::before {
          content: "";
          display: block;
          background-image: url(../../assets/projects/card.svg);

          width: 100%;

          height: 100%;
          position: absolute;
          background-repeat: no-repeat;
        }

        &::after {
          content: attr(data-before-content);
          display: block;
          font-weight: 600;
          font-size: 1.5rem;

          background-image: url(../../assets/projects/semiCircle.svg);
          background-position: center;
          width: 100%;
          top: -3rem;
          height: 25%;
          position: absolute;
          background-repeat: no-repeat;
          color: black;
          padding-top: 1.65rem;
        }
        &__details {
          padding-top: 8rem;
          text-align: center;
          margin-left: 0.13rem;
        }

        &__image {
          margin-top: 1rem;
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          color: black;
          background-color: white;
          margin: 1rem;
          padding: 0.5rem 0 0.5rem 0;
          border-radius: 0.3rem;

          span {
          }
          img {
            transform: scale(0.7);
            margin-left: 0.1rem;
          }
        }
      }
    }
  }
}

@keyframes on-off {
  0% {
    color: transparent;
    -webkit-text-stroke: 3px white;
  }
  50% {
    color: white;
    -webkit-text-stroke: 1px transparent;
  }
  100% {
    color: transparent;
    -webkit-text-stroke: 1px white;
  }
}

@media screen and (max-width: 380px) {
  html {
    font-size: 80%;
  }
  .projects__container {
    &__content {
      &__card__container {
        &__card {
          &::before {
            transform: scale(0.81);
            left: -2.1rem;
            top: -2.8rem;
            width: 124%;
          }
        }
      }
    }
  }
}
@media screen and (max-height: 750px) {
  .projects__container {
    &__content {
      &__card__container {
        &__card {
          &::before {
            transform: scale(0.81);
            left: -2.1rem;
            top: -2.8rem;
            width: 124%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) and (min-width: 768px) {
  .projects__container {
    &__content {
      &__card__container {
        &__card {
          &::before {
            transform: scale(0.75);
            left: -3rem;
            top: -3.5rem;
            width: 134%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .projects__container {
    &__content {
      &__heading {
        padding-top: 1rem;
        font-size: 10rem;
        text-align: center;
        font-weight: 600;
        -webkit-text-stroke: 1px white;
        animation: on-off 5s infinite;
        color: transparent;
      }
      &__card__container {
        margin-top: 5rem;
        margin-bottom: 2rem;
        max-width: 50rem;
        margin-inline: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        // margin-bottom: 15rem;
        &__card {
          &:nth-of-type(1) {
            margin-top: 0rem;
          }
          &:nth-last-of-type(1) {
            margin-bottom: 8rem;
          }
        }
      }
    }
  }
}
