.about__container {
  &__content {
    width: 100%;
    background-size: cover;
    // background-attachment: fixed;
    height: 100%;
    background-repeat: no-repeat;
    position: relative;
    overflow-x: hidden;
    background-image: url(../../assets/mobile/about.png);
    overflow-y: hidden;
    background-color: black;

    h2 {
      padding-top: 14rem;
      font-weight: 500;
      span {
        display: block;
      }
    }
    h3 {
      margin-top: 2rem;
      font-weight: 400;
      font-size: 1rem;
    }
    h4 {
      opacity: 0.05;
      font-size: 10rem;
      margin-left: -1rem;
      position: absolute;
      bottom: 15rem;
    }

    &__me {
      position: absolute;
      bottom: 4.5rem;
      right: 0;
    }
  }
}

@media screen and (max-height: 700px) {
  .about__container {
    &__content {
      &__me {
        bottom: 0rem;
        @media screen and (max-height: 625px) {
          bottom: -2rem;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .about__container {
    overflow-y: hidden;
    &__content {
      background-image: url(../../assets/pc/about.png);
      background-size: contain;
      background-position-x: 8rem;
      background-color: black;

      h2 {
        margin-top: -6rem;
        font-size: 3rem;
        span {
          display: block;
        }
      }
      h3 {
        font-size: 1.5rem;
        margin-top: 4rem;
        span {
          display: block;
        }
      }
      h4 {
        opacity: 0.05;
        font-size: 10rem;
        margin-left: 15rem;
        position: absolute;
        bottom: 10rem;
      }

      &__me {
        position: absolute;
        bottom: -2.5rem;
        right: -5rem;
        transform: scale(0.9);
        img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1390px) and (min-width: 768px) {
  .about__container {
    &__content {
      &__me {
        scale: 0.6;
        bottom: -10rem;
      }
    }
  }
}
@media screen and (max-width: 1150px) and (min-width: 768px) {
  .about__container {
    &__content {
      &__me {
        scale: 0.4;
        bottom: -15rem;
      }
    }
  }
}
