@import "./n.module";

$bg__hero: #0e0e0e;

.inactive {
  color: #8b8b8b;
}

#active {
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 0.1rem;
    position: absolute;
    top: -1.3rem;
    background: rgb(231, 84, 84);

    @media screen and (max-width: 380px) {
      top: -1.1rem;
    }
    @media screen and (max-height: 750px) {
      top: -1.1rem;
    }

    @media screen and (min-width: 768px) {
      height: 110%;
      width: 0.2rem;
      top: -0.5rem;
      left: -3.5rem;
    }
  }
}

.hero__container {
  &__content {
    width: 100%;
    background-size: contain;
    background-image: url(../../assets/mobile/hero.png);
    background-attachment: fixed;
    height: 100%;
    background-repeat: no-repeat;
    background-color: $bg__hero;
    overflow-y: scroll;
    overflow-x: hidden;

    .ba {
      background-attachment: scroll;
    }

    &__menu {
      background-color: #000000;
      height: 5rem;
      width: 100%;
      border-top: 0.5px solid white;
      list-style: none;
      position: fixed;
      z-index: 3;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        color: white;
        text-decoration: none;
        transform: scale(0.9);
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 0.8rem;
        font-weight: 600;
        transition: all 0.2s;

        &:active,
        &:target {
          transform: scale(0.9);
        }
      }
    }

    &__whee {
      position: fixed;
      top: -17rem;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 3;
      width: 100%;
    }

    &__wheel {
      margin: 5rem auto 0;
      border: solid 1px $color__wheel;

      li {
        display: block;
        max-width: 100%;
        border-radius: 50%;
        filter: grayscale(100%);
        // border: solid 5px tomato;
        transition: 0.5s;
        font-size: 1.5rem;
        font-weight: 400;
        transition: all 0.15s;

        &:hover,
        &:active {
          filter: grayscale(0);
        }
      }
    }

    &__logo {
      position: fixed;
      z-index: 4;
      top: 1rem;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &__heading {
      padding-top: 12rem;
      text-align: start;
      font-weight: 500;
      font-size: 4.5rem;
      line-height: 4rem;

      @media screen and (min-width: 540px) {
        font-size: 3.5rem;
      }
      @media screen and (min-width: 620px) {
        font-size: 4rem;
      }

      span {
        display: block;
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 3rem;
      }
    }
    &__paragraph {
      margin-top: 2rem;
      margin-left: 3.5rem;
      font-size: 1.2rem;
      position: relative;
      margin-bottom: 4rem;
      li {
        list-style: none;
      }

      &::before {
        content: "";
        position: absolute;
        width: 0.6rem;
        height: 100%;
        background-color: #2d2c2a;
        left: -1.5rem;
      }
    }

    #a {
      width: 18rem;
      border: none;
      border-radius: 0.6rem;
      padding: 1rem 4rem 1rem 4rem;
      font-family: inherit;
      font-size: 1.2rem;
      font-weight: 400;
      transition: all 0.1s;
      background: white;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        transform: scale(0.8);
        margin-left: 0.5rem;
      }
    }

    &__links {
      margin-top: 2rem;
      list-style: none;
      display: flex;
      margin-bottom: 8rem;
      a {
        margin-right: 2rem;
        &:hover {
          transform: scale(1.1);
        }
        &:active,
        &:target {
          transform: scale(0.9);
        }
      }
    }
  }
}

.audio-enable {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2;
  background-color: #eee;
  background-image: url(../../assets/bg.png);
  background-repeat: no-repeat;

  background-size: cover;
  transition: all 2.5s;

  &__text {
    span {
      // font-size: 2rem;
      color: rgb(231, 84, 84);
      animation: on-off2 2s infinite;
      font-weight: 800;
    }
  }
  h3 {
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 16rem;
    text-align: center;
    color: white;
    // background: black;

    @media screen and (min-width: 380px) {
      font-size: 2.6rem;
      margin-bottom: 6rem;
    }
  }

  div {
    margin-top: 4rem;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    @media screen and (min-width: 768px) {
      justify-content: center;
      gap: 10rem;
    }

    button {
      border: none;
      background: white;
      padding: 1rem 3rem 1rem 3rem;
      font-family: inherit;
      font-size: 1rem;
      border-radius: 0.5rem;
      color: black;
      font-weight: 500;
      animation: on-off3 1s infinite;
    }
  }
}
@keyframes on-off2 {
  0% {
    color: transparent;
    -webkit-text-stroke: 3px rgb(231, 84, 84);
  }
  50% {
    color: rgb(231, 84, 84);
    -webkit-text-stroke: 1px transparent;
  }
  100% {
    color: transparent;
    -webkit-text-stroke: 1px rgb(231, 84, 84);
  }
}
@keyframes on-off3 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1-0.02);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (min-width: 768px) {
  .hero__container {
    &__content {
      background-image: url(../../assets/pc/hero.png);
      background-position: center;
      &__menu {
        background-color: #000000;
        height: 100%;
        width: 10rem;
        border-top: 0.5px solid white;
        list-style: none;
        position: fixed;
        z-index: 3;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        a {
          color: white;
          text-decoration: none;
          transform: scale(1);
          display: flex;
          flex-direction: column;
          text-align: center;
          font-size: 0.8rem;
          font-weight: 600;
          transition: all 0.2s;

          &:hover {
            transform: scale(1.1);
          }
          &:active,
          &:target {
            transform: scale(0.9);
          }
        }
      }

      &__whee {
        position: fixed;
        top: 50%;
        left: 101%;
        transform: translate(-50%, -50%);
        z-index: 3;
        width: 32%;
      }

      &__wheel {
        margin: 0 auto 0 auto;
        border: solid 1px $color__wheel;

        li {
          display: block;
          max-width: 100%;
          border-radius: 50%;
          filter: grayscale(100%);
          // border: solid 5px tomato;
          transition: 0.5s;
          font-size: 1.5rem;
          font-weight: 400;
          transition: all 0.15s;

          &:hover,
          &:active {
            filter: grayscale(0);
          }
        }
      }

      &__logo {
        display: none;
      }

      &__heading {
        font-size: 5rem;

        span {
          font-size: 1.5rem;
        }
      }

      &__paragraph {
        margin-left: 17rem;
        margin-top: 3rem;
        margin-bottom: 4rem;
      }
    }
  }
}
